import http from './http-commons';
import authHeader from './authHeader';
import { API_URL } from './config';
import axios from 'axios';

class MainService {

  getModules() {
    return http.get(API_URL + 'modules/', { headers: authHeader() });
  }

  createRestaurant = data => {
    return http.post(API_URL + "restaurant/", data, { headers: authHeader() });
    // return http.post(API_URL + "restaurant/", data, { headers: authHeader() });
  };

  activateAccount = data => {
    return http.post(API_URL + "reset-account", data, { headers: { 'Content-Type' : 'application/json' } });
  };

  createClient = data => {
    return http.post(API_URL + "client/", data, { headers: { 'Content-Type' : 'application/json' } });
  };
  
  getModule = id => {
    return http.get(`drink/${id}/`, { headers: authHeader() });
  };

  listRestaurant = () => {
    return http.get(API_URL + "restaurant/", { headers: authHeader() });
    // return http.post(API_URL + "restaurant/", data, { headers: authHeader() });
  };

  listClients = () => {
    return http.get(API_URL + "client/", { headers: authHeader() });
    // return http.post(API_URL + "restaurant/", data, { headers: authHeader() });
  };

  listDeliverys = () => {
    return http.get(API_URL + "delivery/", { headers: authHeader() });
    // return http.post(API_URL + "restaurant/", data, { headers: authHeader() });
  };
  removeRestaurant = restau_id => {
    return http.delete(`restaurant/${restau_id}/`, { headers: authHeader() });
  };
  activateRestaurant = ( object_id) => {
    return http.post("admin/validate/account", {object_id: object_id, type_account:1, activate_status: "True", activate_reason: 'Good'},{ headers: authHeader() })
  };
  listRestaurantCategory = () =>{
    return http.get('admin/restaurant/category', { headers: authHeader() });
  };
  CreateRestaurantCategory = (data) =>{
    return http.post('admin/restaurant/category/', data, { headers: authHeader(multipart/form-data) })
  };
  deleteRestaurantCategory = categoryId => {
    return http.delete(`admin/restaurant/category/${categoryId}/`, { headers: authHeader() })
  }
  updateRestaurantCategory = (categoryId, data) =>{
    return http.put(`admin/restaurant/category/${categoryId}/`, data, { headers: authHeader(multipart/form-data) } )
  }

}
export default new MainService();
