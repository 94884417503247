export const MainNav = [
  {
    icon: "pe-7s-graph",
    label: "Tableau de bord",
    to: "/"
  },
  {
    icon: "pe-7s-browser",
    label: "Gestion des accès",
    content: [
      {
        label: "Restaurateur",
        to: "/account/restaurant",
      },
      {
        label: "Livreur",
        to: "/account/delivery",
      },
      {
        label: "Client",
        to: "/account/client",
      },
      {
        label: "Catégorie de Restaurant",
        to: "/restaurant/category",
      }
    ],
  },
  {
    icon: "pe-7s-browser",
    label: "Gestion des plats",
    content: [
      {
        label: "Plats",
        to: "/food/",
      },
      {
        label: "Types de Plats",
        to: "/food/type",
      },
      {
        label: "SubType de Plats",
        to: "/food/subtype",
      }
    ],
  },
  {
    icon: "pe-7s-browser",
    label: "Gestion des boissons",
    content: [
      {
        label: "Boissons",
        to: "/drinks/",
      },
      {
        label: "Categories de boisson",
        to: "/drink/category",
      },
      {
        label: "Types de boisson",
        to: "/drink/type",
      }
    ],
  },
  {
    icon: "pe-7s-browser",
    label: "Gestion des Orders",
    content: [
      {
        label: "Processing Orders",
        to: "/processing/orders/",
      },
      // {
      //   label: "Pending Orders",
      //   to: "/pending/orders/",
      // },
      {
        label: "Approved Orders",
        to: "/approved/orders/",
      },
      {
        label: "Cancelled Orders",
        to: "/cancelled/orders/",
      },
      {
        label: "Orders Out For Delivery",
        to: "/orders/outForDelivery/",
      },
      {
        label: "Completed Orders",
        to: "/orders/Completed/",
      },
    ],
  }
];
