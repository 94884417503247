import React, { useEffect, useState } from "react";
import { Col, Row } from 'reactstrap';
import Button from 'react-bootstrap/Button';
import Table from 'react-bootstrap/Table';
import Base from '../Base';
import foodService from "../../services/foodService";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPen, faTrash, faPlus, faTimes, faUtensils } from "@fortawesome/free-solid-svg-icons";

export default function Client () {

    const [food, setFood] = useState([]);

    useEffect(()=>{
        foodService
        .getAll()
        .then((response) =>{
            setFood(response.data);
        })
    },[]);

    const toggleConfirmDelete = (p) => {
        swal({
          text: "Confirmer la suppression du Plat",
          title: "Suppression",
          icon: "warning",
          buttons: ["Non", "Oui"],
        }).then((value) => {
          if (value) {
            foodService
            .remove(p.id)
              .then(() => {
                swal("Plat supprimé avec succès", {
                  icon: "success",
                }).then(() => {
                  window.location.href = "/food"
                });
              })
              .catch((err) => {
                console.log(err);
                swal("Erreur lors du traitement de la requete", {
                  icon: "error",
                })
              });
          }
        });
      };
    
      const handleDelete = (restaurant) => {
        toggleConfirmDelete(restaurant);
      };
      const toggle = () => {
        resetForm();
        setModal(!modal);
      };
      const resetForm = () => {
        setId(0);
        setName("");
      };

      const closeBtn = (
        <FontAwesomeIcon
          className="close"
          onClick={toggle}
          style={{ cursor: "pointer", fontSize: "1.5rem" }}
          icon={faTimes}
        />
      );

    return (

        <Base>
            <div className="app-inner-layout">

                <div className="app-inner-layout__header-boxed p-0">
                <div className="app-inner-layout__header text-dark bg-white mb-4 d-flex justify-content-between align-content-center">
                    <h1 className='fs-3 pt-2'>Plat</h1>
                </div>
                </div>

        {/* <LoadingOverlay tag="div" active={loading}
          styles={{
            overlay: (base) => ({
              ...base,
              background: "#fff",
              opacity: 0.5,
            }),
          }}
          spinner={<Loader active={loading} type='ball-pulse-rise' />}> */}

            <Table variant="dark" striped bordered hover>
                <thead>
                    <tr>
                    <th>Nom</th>
                    <th>price</th>
                    <th>Types</th>
                    <th>Etat</th>
                    <th>Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        food.map((item, key)=>{
                            return (
                                <tr>
                                    <td>{item.name}</td>
                                    <td><b className='wazieats-color'>{item.price}{" "}FCFA</b></td>
                                    <td><b className='wazieats-color'>{item.foodType}</b></td>
                                    <td>{item.is_active ? <b className='text-success'>Actif</b> : <b className='wazieats-2-color'>Inactif</b>}</td>
                                    <td>
                                        <Row>
                                            <Col>
                                                {/* <Button block className="btn fw-bold btn-login2">Voir Plus</Button> */}
                                                <Button className=" btn-icon-only pt-0 wazi-btn-add float-end "  style={{backgroundColor: "red", border: '1px solid red'}} onClick={(e) => handleDelete(item)} >
                                                   <FontAwesomeIcon icon={faTrash} size="2xs"  />
                                                </Button>
                                            </Col>
                                        </Row>
                                    </td>
                                </tr>
                            )
                        })
                    }
                </tbody>
            </Table>

        {/* </LoadingOverlay> */}

      </div>
    </Base>

    )

}