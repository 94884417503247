import React, { useEffect, useState } from "react";
import { Col, Modal, ModalBody, Row, ModalHeader, FormGroup, Label, Input } from 'reactstrap';
import Button from 'react-bootstrap/Button';
import Table from 'react-bootstrap/Table';
import Base from '../Base';
import orderService from "../../services/orderService";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPen, faTrash, faPlus, faTimes, faUtensils } from "@fortawesome/free-solid-svg-icons";
// const currentUser = JSON.parse(localStorage.getItem('admin_user'));
// const token = currentUser.token;


export default function Orders() {

    const [orders, setOrders] = useState([]);
    const [clientPhone, setClientPhone] = useState([]);
    const [orderedBy, setOrderedBy] = useState('');
    const [deliveryFee, setDeliveryFee] = useState('');
    const [deliveryAddress, setDeliveryAddress] = useState('');
    const [modal, setModal] = useState(false);
    const [id, setId] = useState(0);
    const [loader, setLoader] = useState(false);
    const [totalCost, setTotalCost] = useState('');
    const [timeOfOrder, setTimeOfOrder] = useState('')
    const [restaurantPhone, setRestaurantPhone] = useState('');
    const dateTime = new Date(timeOfOrder);
    // Extracting date
    const date = dateTime.toDateString();
    // Extracting time
    const time = dateTime.toLocaleTimeString();

    const edit = (Orders) => {
        setId(Orders.id);
        setOrderedBy(Orders.ordered_by)
        setDeliveryAddress(Orders.site_delivery);
        setDeliveryFee(Orders.shipping_cost);
        setRestaurantPhone(Orders.restaurant_phone);
        setClientPhone(Orders.client_phone);
        setTotalCost(Orders.total_order_price);
        setTimeOfOrder(Orders.ordered_at);
        setModal(true);
    }

    const toggle = () => {
        resetForm();
        setModal(!modal);
      };
      const resetForm = () => {
        setId(0);
        setOrderedBy("");
      };
    
      const closeBtn = (
        <FontAwesomeIcon
          className="close"
          onClick={toggle}
          style={{ cursor: "pointer", fontSize: "1.5rem" }}
          icon={faTimes}
        />
      );

    useEffect(() => {
        console.log('token',)
        orderService
            .getOrder()
            .then((response) => {
                console.log('orders', response)
                setOrders(response.data.response);
            }).catch((err) => console.log('err', err))
    }, []);

    return (

        <Base>
            <div className="app-inner-layout">

                <div className="app-inner-layout__header-boxed p-0">
                    <div className="app-inner-layout__header text-dark bg-white mb-4 d-flex justify-content-between align-content-center">
                        <h1 className='fs-3 pt-2'> Cancelled Orders</h1>
                    </div>
                </div>

                {/* <LoadingOverlay tag="div" active={loading}
          styles={{
            overlay: (base) => ({
              ...base,
              background: "#fff",
              opacity: 0.5,
            }),
          }}
          spinner={<Loader active={loading} type='ball-pulse-rise' />}> */}

                <Table variant="dark" striped bordered hover>
                    <thead>
                        <tr>
                            <th>Ordered By</th>
                            <th>Total Price</th>
                            <th>Restaurant</th>
                            <th>Pament Method</th>
                            <th> Food Items </th>
                            <th>Drink Items </th>
                            <th>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            orders?.map((item, key) => {
                                return (
                                    <tr>
                                        <td>{item.ordered_by}</td>
                                        <td><b className='wazieats-color'>{item.total_order_price}{" "}FCFA</b></td>
                                        <td><b className='wazieats-color'>{item.restaurant}</b></td>
                                        <td><b>{item.payment_method}</b></td>
                                        <td>
                                            {item?.food_items?.map((food) => <p>{food.food_data.name}{" "}:{" "}{food.food_data.price}</p>)}
                                        </td>
                                        <td>
                                            {item?.drink_items?.map((drinks) => <p>{drinks.drink_data.name}{" "}:{" "}{drinks.drink_data.price}</p>)}
                                        </td>
                                        <td>
                                            <Row>
                                                <Col>
                                                    <Button onClick={(e) => edit(item)} block className="btn fw-bold btn-login2" >Voir Plus</Button>
                                                </Col>
                                                <Col>
                                                    {/* <Button block className="btn fw-bold btn-login2">Voir Plus</Button> */}
                                                    <Button className=" btn-icon-only pt-0 wazi-btn-add float-end " style={{ backgroundColor: "red", border: '1px solid red' }} >
                                                        <FontAwesomeIcon icon={faTrash} size="2xs" />
                                                    </Button>
                                                </Col>
                                            </Row>
                                        </td>
                                    </tr>
                                )
                            })
                        }
                    </tbody>
                </Table>

                {/* </LoadingOverlay> */}

            </div>
            <Row>
                <Col md="12">
                    <Modal
                        className="wazi-modal"
                        isOpen={modal}
                        toggle={toggle}
                        size="lg"
                        backdrop="static"
                    >
                        <ModalHeader
                            style={{ fontSize: "1.5rem" }}
                            toggle={toggle}
                            close={closeBtn}
                        >
                            <FontAwesomeIcon icon={faUtensils} />
                            &nbsp;&nbsp;
                            {id === 0 ? "Ajouter" : "Order"}
                        </ModalHeader>
                        <ModalBody>
                            {loader ? (
                                <div className="text-center py-5">
                                    <div className="text-center py-5">
                                        <div className="text-center py-5">
                                            <Spinner
                                                thickness="4px"
                                                emptyColor="gray.200"
                                                color="green.500"
                                                size="xl"
                                            />
                                        </div>
                                    </div>
                                </div>
                            ) : (
                                <>
                                    <FormGroup row>
                                        <Label for="name" sm={3}>
                                            Ordered By
                                        </Label>
                                        <Col sm={9}>
                                            <Input
                                                value={orderedBy}
                                                onChange={(e) => setOrderedBy(e.target.value)}
                                                type="text"
                                                name="name"
                                                id="name"
                                                placeholder="Entrer le nom du type"
                                            />
                                        </Col>
                                    </FormGroup>
                                    <FormGroup row>
                                        <Label for="description" sm={3}>
                                            Client Phone
                                        </Label>
                                        <Col sm={9}>
                                            <Input
                                                value={clientPhone}
                                                onChange={(e) => setClientPhone(e.target.value)}
                                                // disabled={dDesc}
                                                type="text"
                                                name="restaurateur"
                                                id="restaurateur"
                                            />
                                        </Col>
                                    </FormGroup>
                                    <FormGroup row>
                                        <Label for="name" sm={3}>
                                            Delivery Fee
                                        </Label>
                                        <Col sm={9}>
                                            <Input
                                                value={deliveryFee}
                                                onChange={(e) => setDeliveryFee(e.target.value)}
                                                type="text"
                                                name="email"
                                                id="email"
                                                placeholder="Entrer le nom du type"
                                            />
                                        </Col>
                                    </FormGroup>
                                    <FormGroup row>
                                        <Label for="name" sm={3}>
                                            Delivery Address
                                        </Label>
                                        <Col sm={9}>
                                            <Input
                                                value={deliveryAddress}
                                                onChange={(e) => setDeliveryAddress(e.target.value)}
                                                type="text"
                                                name="address"
                                                id="address"
                                                placeholder="Entrer le nom du type"
                                            />
                                        </Col>
                                    </FormGroup>
                                    <FormGroup row>
                                        <Label for="name" sm={3}>
                                            Total Cost 
                                        </Label>
                                        <Col sm={9}>
                                            <Input
                                                value={totalCost}
                                                onChange={(e) => setTotalCost(e.target.value)}
                                                type="text"
                                                name="email"
                                                id="email"
                                                placeholder="Entrer le nom du type"
                                            />
                                        </Col>
                                    </FormGroup>
                                    <FormGroup row>
                                        <Label for="name" sm={3}>
                                            Date of Order
                                        </Label>
                                        <Col sm={9}>
                                            <Input
                                                value={date}
                                                onChange={(e) => setTimeOfOrder(e.target.value)}
                                                type="text"
                                                name="timeoforder"
                                                id="timeoforder"
                                            />
                                        </Col>
                                    </FormGroup>
                                     <FormGroup row>
                                        <Label for="name" sm={3}>
                                            Time of Order
                                        </Label>
                                        <Col sm={9}>
                                            <Input
                                                value={time}
                                                onChange={(e) => setTimeOfOrder(e.target.value)}
                                                type="text"
                                                name="timeoforder"
                                                id="timeoforder"
                                            />
                                        </Col>
                                    </FormGroup>
                                    <FormGroup row>
                                        <Label for="name" sm={3}>
                                            Restaurant Phone
                                        </Label>
                                        <Col sm={9}>
                                            <Input
                                                value={restaurantPhone}
                                                onChange={(e) => setRestaurantPhone(e.target.value)}
                                                type="text"
                                                name="phone"
                                                id="phone"
                                                placeholder="Entrer le nom du type"
                                            />
                                        </Col>
                                    </FormGroup>
                                </>

                            )}
                        </ModalBody>
                    </Modal>
                </Col>
            </Row>
        </Base>

    )

}