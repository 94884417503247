import { Col, Row } from 'reactstrap';
import Button from 'react-bootstrap/Button';
import Table from 'react-bootstrap/Table';
import Base from '../Base';
import mainService from '../../services/mainService';
import { useState, useEffect } from 'react';

export default function Delivery () {

    const [deliveryList, setDeliverytList] = useState([]);

    useEffect(()=>{
        mainService
        .listDeliverys()
        .then((response) =>{
            setDeliverytList(response.data);
        })
    },[]);

    const data = [
        {
            id: 1,
            name: "Livreur No 1",
            phone: "+237651796157",
            active: true,
        },
        {
            id: 2,
            name: "Livreur No 2",
            phone: "+237651796157",
            active: false,
        },
        {
            id: 3,
            name: "Livreur No 3",
            phone: "+237651796157",
            active: true,
        }
    ]

    return (

        <Base>
            <div className="app-inner-layout">

                <div className="app-inner-layout__header-boxed p-0">
                <div className="app-inner-layout__header text-dark bg-white mb-4 d-flex justify-content-between align-content-center">
                    <h1 className='fs-3 pt-2'>Gestion des Livreurs</h1>
                </div>
                </div>

        {/* <LoadingOverlay tag="div" active={loading}
          styles={{
            overlay: (base) => ({
              ...base,
              background: "#fff",
              opacity: 0.5,
            }),
          }}
          spinner={<Loader active={loading} type='ball-pulse-rise' />}> */}

            <Table variant="dark" striped bordered hover>
                <thead>
                    <tr>
                    <th>Nom</th>
                    <th>Telephone</th>
                    <th>Etat</th>
                    <th>Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        deliveryList.map((item, key)=>{
                            return (
                                <tr>
                                    <td>{item.first_name } {item.last_name }</td>
                                    <td><a href={"tel:"+item.phone}><b className='wazieats-color'>{item.phone}</b></a></td>
                                    <td>{item.is_active ? <b className='text-success'>Actif</b> : <b className='wazieats-2-color'>Inactif</b>}</td>
                                    <td>
                                        <Row>
                                            <Col>
                                                <Button block className="btn fw-bold btn-login2">Voir Plus</Button>
                                            </Col>
                                            <Col>
                                                {
                                                    item.is_active ? 
                                                        <Button  block className="btn fw-bold btn-login-alt">Renvoyer la clé</Button>
                                                        : 
                                                        <Button  block className="btn fw-bold btn-login">Créer la clé</Button>
                                                }
                                            </Col>
                                        </Row>
                                    </td>
                                </tr>
                            )
                        })
                    }
                </tbody>
            </Table>

        {/* </LoadingOverlay> */}

      </div>
    </Base>

    )

}