import React, { useEffect, useState } from "react";
import { Col, Row } from 'reactstrap';
import Button from 'react-bootstrap/Button';
import Table from 'react-bootstrap/Table';
import Base from '../Base';
import mainService from "../../services/mainService";
import drinkService from "../../services/drinkService";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPen, faTrash, faPlus } from "@fortawesome/free-solid-svg-icons";




export default function Client () {

    const [drinkCategory, setDrinkCategory] = useState([]);

    useEffect(() => {
        drinkService
          .getDrinkCategory()
          .then((response) => {
            setDrinkCategory(response.data);
            console.log(response.data);
          })
          .catch((error) => {
            console.log(error);
          });
      }, []);

    return (

        <Base>
            <div className="app-inner-layout">

                <div className="app-inner-layout__header-boxed p-0">
                <div className="app-inner-layout__header text-dark bg-white mb-4 d-flex justify-content-between align-content-center">
                    <h1 className='fs-3 pt-2'>Categories de boisson</h1>
                    <Button
                        className="btn-icon btn-icon-only pt-0 ps-2 wazi-btn-add float-end wazi-btn"
                        >
                    <FontAwesomeIcon icon={faPlus} />
                    </Button>
                </div>
                </div>

            <Table variant="dark" striped bordered hover>
                <thead>
                    <tr>
                    <th>Nom</th>
                    <th>Etat</th>
                    <th>Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        drinkCategory.map((item, key)=>{
                            return (
                                <tr>
                                    <td>{item.name}</td>
                                    <td>{item.is_active ? <b className='text-success'>Actif</b> : <b className='wazieats-2-color'>Inactif</b>}</td>
                                    <td>
                                        <Row>
                                            <Col>
                                                <Button className="btn-icon btn-icon-only pt-0 wazi-btn-add  wazi-btn">
                                                    <FontAwesomeIcon icon={faPen} size="2xs" />
                                                </Button>
                                                <Button className=" btn-icon-only pt-0 wazi-btn-add "  style={{backgroundColor: "red", border: '1px solid red'}} >
                                                   <FontAwesomeIcon icon={faTrash} size="2xs"  />
                                                </Button>
                                            </Col>
                                           
                                        </Row>
                                    </td>
                                </tr>
                            )
                        })
                    }
                </tbody>
            </Table>

        {/* </LoadingOverlay> */}

      </div>
    </Base>

    )

}