import React, { useEffect, useState } from "react";
import { Col, Modal, ModalBody, Row, ModalHeader, FormGroup, Label, Input } from 'reactstrap';
import Button from 'react-bootstrap/Button';
import Table from 'react-bootstrap/Table';
import Base from '../Base';
import mainService from "../../services/mainService";
import drinkService from "../../services/drinkService";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPen, faTrash, faPlus, faTimes, faUtensils } from "@fortawesome/free-solid-svg-icons";




export default function Client() {

    const [drink, setDrinkType] = useState([]);
    const [name, setName] = useState('');
    const [description, setDescription] = useState('');
    const [modal, setModal] = useState(false);
    const [id, setId] = useState(0);
    const [loader, setLoader] = useState(false);

    const toggleConfirmAdd = () => {
        swal({
            text: "Confirmer la ajoute du Type de boisson",
            title: "Confirmation",
            icon: "warning",
            buttons: ["Non", "Oui"],
        }).then((value) => {
            const formData = new FormData();
            formData.append('name', name);
            formData.append('description', description);
            if (id === 0) {
                drinkService
                    .createDrinkType(formData)
                    .then(() => {
                        swal("Type de boisson creé avec succès", {
                            icon: "success",
                        }).then(() => {
                            window.location.href = "/drink/type"
                        });
                    })
                    .catch((err) => {
                        console.log(err);
                        swal("Erreur lors du traitement de la requete", {
                            icon: "error",
                        }).then(() => {
                            window.location.href = "/drink/type"
                        })
                    });
            } else {
                drinkService
                    .updateDrinkType(id, formData)
                    .then(() => {
                        swal("Type de boisson mise a jour avec success", {
                            icon: "success",
                        }).then(() => {
                            window.location.href = "/drink/type"
                        })
                    })
                    .catch((err) => {
                        console.log(err);
                        swal("Erreur lors du traitement de la requete", {
                            icon: "error",
                        })
                    })
            }
        });
    };

    const toggleConfirmDelete = (p) => {
        swal({
            text: "Confirmer la suppression du type de boisson",
            title: "Suppression",
            icon: "warning",
            buttons: ["Non", "Oui"],
        }).then((value) => {
            if (value) {
                drinkService
                    .deleteDrinkType(p.id)
                    .then(() => {
                        swal("Type de boisson supprimé avec succès", {
                            icon: "success",
                        }).then(() => {
                            window.location.href = "/drink/type"
                        });
                    })
                    .catch((err) => {
                        console.log(err);
                        swal("Erreur lors du traitement de la requete", {
                            icon: "error",
                        })
                    });
            }
        });
    };

    const handleDelete = (drinkType) => {
        toggleConfirmDelete(drinkType);
    };

    const edit = (drinkType) => {
        setId(drinkType.id);
        setName(drinkType.name)
        setDescription(drinkType.description);
        setModal(true);
    }

    const handleCreate = () => {
        toggleConfirmAdd();

    }

    const resetForm = () => {
        setId(0);
        setName("");
        setDescription("");
    };
    const toggle = () => {
        resetForm();
        setModal(!modal);
    };

    const closeBtn = (
        <FontAwesomeIcon
            className="close"
            onClick={toggle}
            style={{ cursor: "pointer", fontSize: "1.5rem" }}
            icon={faTimes}
        />
    );
    useEffect(() => {
        drinkService
            .getDrinkType()
            .then((response) => {
                setDrinkType(response.data);
                console.log(response.data);
            })
            .catch((error) => {
                console.log(error);
            });
    }, []);

    return (

        <Base>
            <div className="app-inner-layout">

                <div className="app-inner-layout__header-boxed p-0">
                    <div className="app-inner-layout__header text-dark bg-white mb-4 d-flex justify-content-between align-content-center">
                        <h1 className='fs-3 pt-2'>Types de boisson</h1>
                        <Button
                            className="btn-icon btn-icon-only pt-0 ps-2 wazi-btn-add float-end wazi-btn"
                            onClick={toggle}
                        >
                            <FontAwesomeIcon icon={faPlus} />
                        </Button>
                    </div>
                </div>

                <Table variant="dark" striped bordered hover>
                    <thead>
                        <tr>
                            <th>Nom</th>
                            <th>Etat</th>
                            <th>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            drink.map((item, key) => {
                                return (
                                    <tr>
                                        <td>{item.name}</td>
                                        <td>{item.is_active ? <b className='text-success'>Actif</b> : <b className='wazieats-2-color'>Inactif</b>}</td>
                                        <td>
                                            <Row>
                                                <Col>
                                                    <Button className="btn-icon btn-icon-only pt-0 wazi-btn-add  wazi-btn" onClick={(e) => edit(item)}>
                                                        <FontAwesomeIcon icon={faPen} size="2xs" />
                                                    </Button>
                                                    <Button className=" btn-icon-only pt-0 wazi-btn-add " style={{ backgroundColor: "red", border: '1px solid red' }} onClick={(e) => handleDelete(item)} >
                                                        <FontAwesomeIcon icon={faTrash} size="2xs" />
                                                    </Button>
                                                </Col>

                                            </Row>
                                        </td>
                                    </tr>
                                )
                            })
                        }
                    </tbody>
                </Table>

                {/* </LoadingOverlay> */}

            </div>
            <Row>
                <Col md="12">
                    <Modal
                        className="wazi-modal"
                        isOpen={modal}
                        toggle={toggle}
                        size="lg"
                        backdrop="static"
                    >
                        <ModalHeader
                            style={{ fontSize: "1.5rem" }}
                            toggle={toggle}
                            close={closeBtn}
                        >
                            <FontAwesomeIcon icon={faUtensils} />
                            &nbsp;&nbsp;
                            {id === 0 ? "Ajouter un Type" : "Mise à jour d'un Type"}
                        </ModalHeader>
                        <ModalBody>
                            {loader ? (
                                <div className="text-center py-5">
                                    <div className="text-center py-5">
                                        <div className="text-center py-5">
                                            <Spinner
                                                thickness="4px"
                                                emptyColor="gray.200"
                                                color="green.500"
                                                size="xl"
                                            />
                                        </div>
                                    </div>
                                </div>
                            ) : (
                                <>
                                    <FormGroup row>
                                        <Label for="name" sm={3}>
                                            Nom du Type *
                                        </Label>
                                        <Col sm={9}>
                                            <Input
                                                value={name}
                                                onChange={(e) => setName(e.target.value)}
                                                type="text"
                                                name="name"
                                                id="name"
                                                placeholder="Entrer le nom du type"
                                            />
                                        </Col>
                                    </FormGroup>
                                    <FormGroup row>
                                        <Label for="description" sm={3}>
                                            Description sur le Type
                                        </Label>
                                        <Col sm={9}>
                                            <Input
                                                value={description}
                                                onChange={(e) => setDescription(e.target.value)}
                                                // disabled={dDesc}
                                                type="textarea"
                                                name="description"
                                                id="description"
                                                placeholder="Entrer la description du type"
                                            />
                                        </Col>
                                    </FormGroup>
                                    <FormGroup check row className="mt-5">
                                        <Col sm={{ size: 2, offset: 10 }}>
                                            <Button type="submit" block className="wazi-btn" onClick={() => handleCreate()}>
                                                {id === 0 ? "Ajouter" : "Mettre à jour"}
                                            </Button>
                                        </Col>
                                    </FormGroup>
                                </>

                            )}
                        </ModalBody>
                    </Modal>
                </Col>
            </Row>
        </Base>

    )

}