import Table from 'react-bootstrap/Table';
import React, { useEffect, useCallback, useMemo, useState } from "react";
import Base from '../Base';
import Select from 'react-select'
import { Col, Modal, ModalBody, Row, ModalHeader, FormGroup, Label, Input } from 'reactstrap';
import AsyncCreatableSelect from 'react-select/async-creatable';
import swal from 'sweetalert';
import { Loader } from "react-loaders";
import LoadingOverlay from "react-loading-overlay-ts";
import Button from 'react-bootstrap/Button';
import mainService from '../../services/mainService';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPen, faTrash, faPlus, faTimes, faUtensils } from "@fortawesome/free-solid-svg-icons";

export default function Restaurant() {
  const [restaurantList, setRestaurantList] = useState([]);
  const [restaurateur, setRestaurateur] = useState([]);
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [address, setAddress] = useState('');
  const [modal, setModal] = useState(false);
  const [id, setId] = useState(0);
  const [loader, setLoader] = useState(false);
  const [phone, setPhone] = useState('');
  const [itemToDelete, setItemToDelete] = useState(null);


  useEffect(() => {
    mainService
      .listRestaurant()
      .then((response) => {
        setRestaurantList(response.data);
      })
  }, []);

  const toggleConfirmDelete = (p) => {
    swal({
      text: "Confirmer la suppression du Restaurant",
      title: "Suppression",
      icon: "warning",
      buttons: ["Non", "Oui"],
    }).then((value) => {
      if (value) {
        mainService
          .removeRestaurant(p.id)
          .then(() => {
            swal("Restaurant supprimé avec succès", {
              icon: "success",
            }).then(() => {
              window.location.href = "/account/restaurant"
            });
          })
          .catch((err) => {
            console.log(err);
            swal("Erreur lors du traitement de la requete", {
              icon: "error",
            })
          });
      }
    });
  };

  const handleDelete = (restaurant) => {
    toggleConfirmDelete(restaurant);
  };

  const toggleActivateAccount = (p) => {
    swal({
      text: "Confirmer la Activation du Restaurant",
      title: "Activation",
      icon: "warning",
      buttons: ["Non", "Oui"],
    }).then((value) => {
      if (value) {
        mainService
          .activateRestaurant(p.id)
          .then(() => {
            console.log(p.id);
            swal("Restaurant Active avec succès", {
              icon: "success",
            }).then(() => {
              window.location.href = "/account/restaurant"
            });
          })
          .catch((err) => {
            console.log(err);
            swal("Erreur lors du traitement de la requete", {
              icon: "error",
            });
          });
      }
    });
  };

  const handleActivation = (restaurant) => {
    toggleActivateAccount(restaurant);
  };
  const edit = (restaurant) => {
    setId(restaurant.id);
    setName(restaurant.name)
    setAddress(restaurant.address);
    setEmail(restaurant.email);
    setPhone(restaurant.phone);
    setRestaurateur(restaurant.user_first_name + restaurant.user_last_name )
    setModal(true);
}
  const toggle = () => {
    resetForm();
    setModal(!modal);
  };
  const resetForm = () => {
    setId(0);
    setName("");
  };

  const closeBtn = (
    <FontAwesomeIcon
      className="close"
      onClick={toggle}
      style={{ cursor: "pointer", fontSize: "1.5rem" }}
      icon={faTimes}
    />
  );


  return (

    <Base>
      <div className="app-inner-layout">

        <div className="app-inner-layout__header-boxed p-0">
          <div className="app-inner-layout__header text-dark bg-white mb-4 d-flex justify-content-between align-content-center">
            <h1 className='fs-3 pt-2'>Gestion des restaurants</h1>
          </div>
        </div>

        {/* <LoadingOverlay tag="div" active={loading}
          styles={{
            overlay: (base) => ({
              ...base,
              background: "#fff",
              opacity: 0.5,
            }),
          }}
          spinner={<Loader active={loading} type='ball-pulse-rise' />}> */}

        <Table variant="dark" striped bordered hover>
          <thead>
            <tr>
              <th>Nom</th>
              <th>Restaurateur</th>
              <th>Etat</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {
              restaurantList.map((item, key) => {
                return (
                  <tr>
                    <td>{item.name}</td>
                    <td>{item.user_first_name + " " + item.user_last_name}</td>
                    <td>{item.is_active ? <b className='text-success'>Actif</b> : <b className='wazieats-2-color'>Inactif</b>}</td>
                    <td>
                      <Row>
                        <Col>
                          <Button onClick={(e) => edit(item)} block className="btn fw-bold btn-login2" >Voir Plus</Button>
                        </Col>
                        <Col>
                          {
                            item.is_active ?
                              <Button block className="btn fw-bold btn-login-alt">Renvoyer la clé</Button>
                              :
                              <Button block className="btn fw-bold btn-login" onClick={(e) => handleActivation(item)}>Créer la clé</Button>
                          }

                        </Col>
                        <Col>
                          <Button className=" btn-icon-only pt-0 wazi-btn-add float-end " style={{ backgroundColor: "red", border: '1px solid red' }} onClick={(e) => handleDelete(item)} >
                            <FontAwesomeIcon icon={faTrash} size="2xs" />
                          </Button>
                        </Col>
                      </Row>
                    </td>
                  </tr>
                )
              })
            }
          </tbody>
        </Table>

        {/* </LoadingOverlay> */}

      </div>
      <Row>
        <Col md="12">
          <Modal
            className="wazi-modal"
            isOpen={modal}
            toggle={toggle}
            size="lg"
            backdrop="static"
          >
            <ModalHeader
              style={{ fontSize: "1.5rem" }}
              toggle={toggle}
              close={closeBtn}
            >
              <FontAwesomeIcon icon={faUtensils} />
              &nbsp;&nbsp;
              {id === 0 ? "Ajouter" : "Restaurant"}
            </ModalHeader>
            <ModalBody>
              {loader ? (
                <div className="text-center py-5">
                  <div className="text-center py-5">
                    <div className="text-center py-5">
                      <Spinner
                        thickness="4px"
                        emptyColor="gray.200"
                        color="green.500"
                        size="xl"
                      />
                    </div>
                  </div>
                </div>
              ) : (
                <>
                  <FormGroup row>
                    <Label for="name" sm={3}>
                      Nom
                    </Label>
                    <Col sm={9}>
                      <Input
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        type="text"
                        name="name"
                        id="name"
                        placeholder="Entrer le nom du type"
                      />
                    </Col>
                  </FormGroup>
                  <FormGroup row>
                    <Label for="description" sm={3}>
                      Restaurateur
                    </Label>
                    <Col sm={9}>
                      <Input
                        value={restaurateur}
                        onChange={(e) => setRestaurateur(e.target.value)}
                        // disabled={dDesc}
                        type="text"
                        name="restaurateur"
                        id="restaurateur"
                        placeholder="Entrer la description du type"
                      />
                    </Col>
                  </FormGroup>
                  <FormGroup row>
                    <Label for="name" sm={3}>
                      Email
                    </Label>
                    <Col sm={9}>
                      <Input
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        type="text"
                        name="email"
                        id="email"
                        placeholder="Entrer le nom du type"
                      />
                    </Col>
                  </FormGroup>
                  <FormGroup row>
                    <Label for="name" sm={3}>
                      Address
                    </Label>
                    <Col sm={9}>
                      <Input
                        value={address}
                        onChange={(e) => setAddress(e.target.value)}
                        type="text"
                        name="address"
                        id="address"
                        placeholder="Entrer le nom du type"
                      />
                    </Col>
                  </FormGroup>
                  <FormGroup row>
                    <Label for="name" sm={3}>
                      Phone
                    </Label>
                    <Col sm={9}>
                      <Input
                        value={phone}
                        onChange={(e) => setPhone(e.target.value)}
                        type="text"
                        name="phone"
                        id="phone"
                        placeholder="Entrer le nom du type"
                      />
                    </Col>
                  </FormGroup>
                </>

              )}
            </ModalBody>
          </Modal>
        </Col>
      </Row>
    </Base>

  )

}