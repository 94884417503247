import React, { useEffect, useState } from "react";
import { Col, Modal, ModalBody, Row, ModalHeader, FormGroup, Label, Input, FormText } from 'reactstrap';
import Button from 'react-bootstrap/Button';
import Table from 'react-bootstrap/Table';
import Base from '../Base';
import { useDropzone } from "react-dropzone";
import drinkService from "../../services/drinkService";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPen, faTrash, faPlus, faTimes, faUtensils } from "@fortawesome/free-solid-svg-icons";


export default function Client() {

    const [drinks, setDrinks] = useState([]);
    const [name, setName] = useState('');
    const [description, setDescription] = useState('');
    const [price, setPrice] = useState('');
    const [drinkType, setDrinkType] = useState('');
    const [drinkTypes, setDrinkTypes] = useState([]);
    const [drinkCategory, setDrinkCategory] = useState('');
    const [drinkCategories, setDrinkCategories] = useState([]);
    const [modal, setModal] = useState(false);
    const [id, setId] = useState(0);
    const [loader, setLoader] = useState(false);
    const [image, setImage] = useState("");
    const [img, setImg] = useState(true);
    const [files, setFiles] = useState([]);
    const currentUser = JSON.parse(localStorage.getItem('admin_user'));
    const token = currentUser.token;


    const { getRootProps, getInputProps } = useDropzone({
        multiple: false,
        onDrop: (acceptedFiles) => {
            setFiles(
                acceptedFiles.map((file) =>
                    Object.assign(file, {
                        preview: URL.createObjectURL(file),
                    })
                )
            );
            setImage("");
        },
    });

    const thumbs = files.map((file) => (
        <div className="thumbs" key={file.name}>
            <div className="thumbs-inner">
                <img
                    src={file.preview}
                    alt={file.name}
                    // Revoke data uri after image is loaded
                    onLoad={() => {
                        URL.revokeObjectURL(file.preview);
                    }}
                />
            </div>
        </div>
    ));

    const toggleConfirmAdd = () => {
        swal({
            text: "Confirmer la ajoute du boisson",
            title: "Confirmation",
            icon: "warning",
            buttons: ["Non", "Oui"],
        }).then((value) => {
            const formData = new FormData();
            formData.append('name', name);
            formData.append('description', description);
            formData.append('price', price);
            formData.append('drinkType', drinkType);
            formData.append('drinkCategory', drinkCategory);
            // formData.append("drinkPicture", files[0]);
            if (!image) {
                formData.append("drinkPicture", files[0]);
              } else {
                // formData.append("drinkPicture", image);
              }
            if (id === 0) {
                drinkService
                    .createDrink(formData)
                    .then(() => {
                        swal(" boisson creé avec succès", {
                            icon: "success",
                        }).then(() => {
                            window.location.href = "/drinks"
                        });
                    })
                    .catch((err) => {
                        console.log(err);
                        swal("Erreur lors du traitement de la requete", {
                            icon: "error",
                        }).then(() => {
                            window.location.href = "/drinks"
                        })
                    });
            } else {
                drinkService
                    .updateDrink(id, formData)
                    .then(() => {
                        swal("Type de boisson mise a jour avec success", {
                            icon: "success",
                        }).then(() => {
                            window.location.href = "/drinks"
                        })
                    })
                    .catch((err) => {
                        console.log(err);
                        swal("Erreur lors du traitement de la requete", {
                            icon: "error",
                        })
                    })
            }
        });
    };

    const toggleConfirmDelete = (p) => {
        swal({
            text: "Confirmer la suppression du boisson",
            title: "Suppression",
            icon: "warning",
            buttons: ["Non", "Oui"],
        }).then((value) => {
            if (value) {
                drinkService
                    .deleteDrink(p.id)
                    .then(() => {
                        swal(" boisson supprimé avec succès", {
                            icon: "success",
                        }).then(() => {
                            window.location.href = "/drinks"
                        });
                    })
                    .catch((err) => {
                        console.log(err);
                        swal("Erreur lors du traitement de la requete", {
                            icon: "error",
                        })
                    });
            }
        });
    };

    const handleDelete = (drink) => {
        toggleConfirmDelete(drink);
    };

    const edit = (drink) => {
        setId(drink.id);
        setName(drink.name);
        setDescription(drink.description);
        setPrice(drink.price);
        setDrinkType(drink.drinkType);
        setDrinkCategory(drink.drinkCategory);
        let ima = drink.drinkPicture
            ? drink.drinkPicture
            : drink.admin_drink.drinkPicture;
        setImage(ima);
        setModal(true);
    }

    const handleCreate = () => {
        toggleConfirmAdd();

    }

    const resetForm = () => {
        setId(0);
        setName("");
        setDescription("");
        setPrice("")
        setDrinkCategory("")
        setDrinkType("")
        setFiles([]);
        setImage(false);

    };
    const toggle = () => {
        resetForm();
        setModal(!modal);
    };

    const closeBtn = (
        <FontAwesomeIcon
            className="close"
            onClick={toggle}
            style={{ cursor: "pointer", fontSize: "1.5rem" }}
            icon={faTimes}
        />
    );

    useEffect(() => {
        drinkService
            .getAll()
            .then((response) => {
                setDrinks(response.data);
            })
        drinkService
            .getDrinkCategory()
            .then((response) => {
                setDrinkCategories(response.data);
            })
            .catch((error) => {
                console.log(error);
            });

        drinkService
            .getDrinkType()
            .then((response) => {
                setDrinkTypes(response.data);
            })
            .catch((error) => {
                console.log(error);
            });
    }, []);

    return (

        <Base>
            <div className="app-inner-layout">

                <div className="app-inner-layout__header-boxed p-0">
                    <div className="app-inner-layout__header text-dark bg-white mb-4 d-flex justify-content-between align-content-center">
                        <h1 className='fs-3 pt-2'>Biosson</h1>
                        <Button
                            className="btn-icon btn-icon-only pt-0 ps-2 wazi-btn-add float-end wazi-btn"
                            onClick={toggle}
                        >
                            <FontAwesomeIcon icon={faPlus} />
                        </Button>
                    </div>
                </div>

                {/* <LoadingOverlay tag="div" active={loading}
          styles={{
            overlay: (base) => ({
              ...base,
              background: "#fff",
              opacity: 0.5,
            }),
          }}
          spinner={<Loader active={loading} type='ball-pulse-rise' />}> */}

                <Table variant="dark" striped bordered hover>
                    <thead>
                        <tr>
                            <th>Nom</th>
                            <th>price</th>
                            <th>Categorie</th>
                            <th>Etat</th>
                            <th>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            drinks.map((item, key) => {
                                return (
                                    <tr>
                                        <td>{item.name}</td>
                                        <td><b className='wazieats-color'>{item.price}{" "}FCFA</b></td>
                                        <td><b className='wazieats-color'>{item.drinkCategory}</b></td>
                                        <td>{item.is_active ? <b className='text-success'>Actif</b> : <b className='wazieats-2-color'>Inactif</b>}</td>
                                        <td>
                                            <Row>
                                                <Col>
                                                    <Button className="btn-icon btn-icon-only pt-0 wazi-btn-add  wazi-btn float-end" onClick={(e) => edit(item)}>
                                                        <FontAwesomeIcon icon={faPen} size="2xs" />
                                                    </Button>
                                                    <Button className=" btn-icon-only pt-0 wazi-btn-add float-end " style={{ backgroundColor: "red", border: '1px solid red' }} >
                                                        <FontAwesomeIcon icon={faTrash} size="2xs" onClick={(e) => handleDelete(item)} />
                                                    </Button>
                                                </Col>
                                            </Row>
                                        </td>
                                    </tr>
                                )
                            })
                        }
                    </tbody>
                </Table>

                {/* </LoadingOverlay> */}

            </div>
            <Row>
                <Col md="12">
                    <Modal
                        className="wazi-modal"
                        isOpen={modal}
                        toggle={toggle}
                        size="lg"
                        backdrop="static"
                    >
                        <ModalHeader
                            style={{ fontSize: "1.5rem" }}
                            toggle={toggle}
                            close={closeBtn}
                        >
                            <FontAwesomeIcon icon={faUtensils} />
                            &nbsp;&nbsp;
                            {id === 0 ? "Ajouter un biosson" : "Mise à jour d'un biosson"}
                        </ModalHeader>
                        <ModalBody>
                            {loader ? (
                                <div className="text-center py-5">
                                    <div className="text-center py-5">
                                        <div className="text-center py-5">
                                            <Spinner
                                                thickness="4px"
                                                emptyColor="gray.200"
                                                color="green.500"
                                                size="xl"
                                            />
                                        </div>
                                    </div>
                                </div>
                            ) : (
                                <>
                                    <Col>
                                        <FormGroup row>
                                            <Label for="photo" sm={3}>
                                                Photo *
                                                <br />
                                                <span className="text-muted">(Recommandée)</span>
                                            </Label>
                                            <Col
                                                sm={3}
                                                {...getRootProps({
                                                    className: "wazi-dropzone text-center",
                                                })}
                                            >
                                                <input
                                                    {...getInputProps()}
                                                    type="file"
                                                    name="photo"
                                                    id="photo"
                                                />
                                                <FormText className="text-center" color="muted">
                                                    Rechercher ou Déposer une photo ici
                                                </FormText>
                                            </Col>
                                            {files.length > 0 ? (
                                                <Col sm={3} className="thumbs-container">
                                                    {thumbs}
                                                </Col>
                                            ) : (
                                                <>
                                                    {image ? (
                                                        <Col sm={3} className="thumbs-container">
                                                            <div className="thumbs">
                                                                <div className="thumbs-inner">
                                                                    <img src={image} alt="WaziEats" />
                                                                </div>
                                                            </div>
                                                        </Col>
                                                    ) : (
                                                        <></>
                                                    )}
                                                </>
                                            )}
                                        </FormGroup>
                                    </Col>
                                    <FormGroup row>
                                        <Label for="name" sm={3}>
                                            Nom du biosson *
                                        </Label>
                                        <Col sm={9}>
                                            <Input
                                                value={name}
                                                onChange={(e) => setName(e.target.value)}
                                                type="text"
                                                name="name"
                                                id="name"
                                                placeholder="Entrer le nom du type"
                                            />
                                        </Col>
                                    </FormGroup>
                                    <FormGroup row>
                                        <Label for="description" sm={3}>
                                            Description du biosson
                                        </Label>
                                        <Col sm={9}>
                                            <Input
                                                value={description}
                                                onChange={(e) => setDescription(e.target.value)}
                                                // disabled={dDesc}
                                                type="textarea"
                                                name="description"
                                                id="description"
                                                placeholder="Entrer la description du biosson"
                                            />
                                        </Col>
                                    </FormGroup>
                                    <FormGroup row>
                                        <Label for="description" sm={3}>
                                            Priz de biosson
                                        </Label>
                                        <Col sm={9}>
                                            <Input
                                                value={price}
                                                onChange={(e) => setPrice(e.target.value)}
                                                // disabled={dDesc}
                                                type="textarea"
                                                name="description"
                                                id="description"
                                                placeholder="Entrer le priz de biosson"
                                            />
                                        </Col>
                                    </FormGroup>
                                    <FormGroup row>
                                        <Label for="category" sm={3}>
                                            Catégorie de boisson *
                                        </Label>
                                        <Col sm={9}>
                                            {(drinkCategory === "" || drinkCategory === null) ? (<Input
                                                value={drinkCategory}
                                                onChange={(e) => setDrinkCategory(e.target.value)}
                                                type="select"
                                                name="category"
                                                id="category"
                                            >
                                                <option value="">Sélectionner une catégorie</option>
                                                {drinkCategories.map((category, index) => (
                                                    <option
                                                        key={`category-${index}`}
                                                        value={category.id}
                                                    >
                                                        {category.name}
                                                    </option>
                                                ))}
                                            </Input>) : <Input
                                                value={drinkCategory}
                                                onChange={(e) => setDrinkCategory(e.target.value)}
                                                type="text"
                                                name="category"
                                                id="category"
                                            />}
                                        </Col>
                                    </FormGroup>

                                    <FormGroup row>
                                        <Label for="type" sm={3}>
                                            Type de boisson *
                                        </Label>
                                        <Col sm={9}>
                                            {(drinkType === "" || drinkType === null) ? (<Input
                                                value={drinkType}
                                                onChange={(e) => setDrinkType(e.target.value)}
                                                type="select"
                                                name="type"
                                                id="type"
                                            >
                                                <option value="">Sélectionner un type</option>
                                                {drinkTypes.map((t, index) => (
                                                    <option key={`drinkType-${index}`} value={t.id}>
                                                        {t.name}
                                                    </option>
                                                ))}
                                            </Input>) : <Input
                                                value={drinkType}
                                                onChange={(e) => setDrinkType(e.target.value)}
                                                type="text"
                                                name="type"
                                                id="type"
                                            />}

                                        </Col>
                                    </FormGroup>
                                    <FormGroup check row className="mt-5">
                                        <Col sm={{ size: 2, offset: 10 }}>
                                            <Button type="submit" block className="wazi-btn" onClick={() => handleCreate()}>
                                                {id === 0 ? "Ajouter" : "Mettre à jour"}
                                            </Button>
                                        </Col>
                                    </FormGroup>
                                </>

                            )}
                        </ModalBody>
                    </Modal>
                </Col>
            </Row>
        </Base>

    )

}