import React, {Fragment, useState} from "react";
import { connect } from "react-redux";
import { Route, Switch } from "react-router-dom";
import cx from "classnames";
import { withRouter } from "react-router-dom";
import Login from './pages/Login';
import Dashboard from "./pages/Dashboard";
import Error from './pages/Error';
import { ChakraProvider } from '@chakra-ui/react'
import { ToastContainer } from "react-toastify";
import { extendTheme } from "@chakra-ui/react"

import ResizeDetector from "react-resize-detector";
import PlateContext from "./context/selectPlates";
import Restaurant from "./pages/Access/Restaurant";
import Delivery from "./pages/Access/Delivery";
import Client from "./pages/Access/Client";
import Drinks from "./pages/Drinks/Drinks";
import DrinkType from "./pages/Drinks/DrinkType"
import DrinkCategory from "./pages/Drinks/DrinkCategory"
import PlateTypes from "./pages/Plates/PlateTypes"
import Plates from "./pages/Plates/Plates"
import PlateSubType from "./pages/Plates/PlateSubType"
import RestaurantCategory from "./pages/Access/RestaurantCategory";
import Orders from "./pages/Orders/Orders";
import ApprovedOrder from "./pages/Orders/Approved Orders";
import OutForDelivery from "./pages/Orders/OutForDeliveryOrders";
import CompletedOrder from "./pages/Orders/CompletedOrder";
import PendingOrder from "./pages/Orders/PendingOrders";
import ProcessingOrder from "./pages/Orders/ProcessingOrders";



function App(props) {
  let {
    colorScheme,
    enableFixedHeader,
    enableFixedSidebar,
    enableFixedFooter,
    enableClosedSidebar,
    closedSmallerSidebar,
    enableMobileMenu,
    enablePageTabsAlt,
  } = props;

  const theme = extendTheme({
    colors: {
      brand: {
        primary: "#0ab44e",
        secondary: "#f8b545"
      },
      primary: {
        _default: '#0ab44e',
      }
    },
  })

  const [plate, setPlate] = useState([]);

  return (

      <ResizeDetector
          handleWidth
          render={({ width }) => (
            <Fragment>
              <ChakraProvider theme={theme}>
                <PlateContext.Provider value={{ plate, setPlate}}>
                  <div
                    className={cx(
                      "app-container app-theme-" + colorScheme,
                      { "fixed-header": enableFixedHeader },
                      { "fixed-sidebar": enableFixedSidebar || width < 1250 },
                      { "fixed-footer": enableFixedFooter },
                      { "closed-sidebar": enableClosedSidebar || width < 1250 },
                      {
                        "closed-sidebar-mobile": closedSmallerSidebar || width < 1250,
                      },
                      { "sidebar-mobile-open": enableMobileMenu },
                      { "body-tabs-shadow-btn": enablePageTabsAlt }
                    )}>
                      <Switch>
                        <Route exact path="/" component={Dashboard} />
                        <Route exact path="/login" component={Login} />
                        <Route exact path="/account/client" component={Client} />
                        <Route exact path="/account/delivery" component={Delivery} />
                        <Route exact path="/account/restaurant" component={Restaurant} />
                        <Route exact path="/drinks" component={Drinks} />
                        <Route exact path="/drink/type" component={DrinkType} />
                        <Route exact path="/drink/category" component={DrinkCategory} />
                        <Route exact path="/food/type" component={PlateTypes} />
                        <Route exact path="/food" component={Plates} />
                        <Route exact path="/restaurant/category" component={RestaurantCategory} />
                        <Route exact path="/food/subtype" component={PlateSubType} />
                        <Route exact path="/cancelled/orders" component={Orders} />
                        <Route exact path="/Approved/orders" component={ApprovedOrder} />
                        {/* <Route exact path="/pending/orders" component={PendingOrder} /> */}
                        <Route exact path="/processing/orders" component={ProcessingOrder} />
                        <Route exact path="/orders/outForDelivery" component={OutForDelivery} />
                        <Route exact path="/orders/Completed/" component={CompletedOrder} />



                        <Route path="*" component={Error} />
                      </Switch>
                  </div>
                  <ToastContainer/>
                </PlateContext.Provider>
              </ChakraProvider>
            </Fragment>
          )}
        />

  )

}

const mapStateToProp = (state) => ({
  colorScheme: state.ThemeOptions.colorScheme,
  enableFixedHeader: state.ThemeOptions.enableFixedHeader,
  enableMobileMenu: state.ThemeOptions.enableMobileMenu,
  enableFixedFooter: state.ThemeOptions.enableFixedFooter,
  enableFixedSidebar: state.ThemeOptions.enableFixedSidebar,
  enableClosedSidebar: state.ThemeOptions.enableClosedSidebar,
  enablePageTabsAlt: state.ThemeOptions.enablePageTabsAlt,
});

export default withRouter(connect(mapStateToProp)(App));
//export default App;
