import React, { useEffect, useState } from "react";
import { Col, Modal, ModalBody, Row, ModalHeader, FormGroup, Label, Input } from 'reactstrap';
import Button from 'react-bootstrap/Button';
import Table from 'react-bootstrap/Table';
import Base from '../Base';
import foodService from "../../services/foodService";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPen, faTrash, faPlus, faTimes, faUtensils } from "@fortawesome/free-solid-svg-icons";




export default function PlateSubType() {

    const [foodSubType, setFoodSubType] = useState([]);

    const [name, setName] = useState('');
    const [description, setDescription] = useState('');
    const [modal, setModal] = useState(false);
    const [id, setId] = useState(0);
    const [loader, setLoader] = useState(false);
    const [foodType, setFoodType] = useState([]);
    const [type, setType] = useState(null);

    const toggleConfirmAdd = () => {
        swal({
            text: "Confirmer la ajoute du Type de plat",
            title: "Confirmation",
            icon: "warning",
            buttons: ["Non", "Oui"],
        }).then((value) => {
            const formData = new FormData();
            formData.append('name', name);
            formData.append('description', description);
            formData.append('foodType', Number(type));
            if (id === 0) {
                foodService
                    .createFoodSubType(formData)
                    .then(() => {
                        swal("Type de plat creé avec succès", {
                            icon: "success",
                        }).then(() => {
                            window.location.href = "/food/subtype"
                        });
                    })
                    .catch((err) => {
                        console.log(err);
                        swal("Erreur lors du traitement de la requete", {
                            icon: "error",
                        })
                    });
            } else {
                foodService
                    .updateFoodSubType(id, formData)
                    .then(() => {
                        swal("Type de plat mise a jour avec success", {
                            icon: "success",
                        }).then(() => {
                            window.location.href = "/food/subtype"
                        })
                    })
                    .catch((err) => {
                        console.log(err);
                        swal("Erreur lors du traitement de la requete", {
                            icon: "error",
                        })
                    })
            }
        });
    };

    const toggleConfirmDelete = (p) => {
        swal({
            text: "Confirmer la suppression du type de plat",
            title: "Suppression",
            icon: "warning",
            buttons: ["Non", "Oui"],
        }).then((value) => {
            if (value) {
                foodService
                    .deleteFoodSubType(p.id)
                    .then(() => {
                        swal("Type de plat supprimé avec succès", {
                            icon: "success",
                        }).then(() => {
                            window.location.href = "/food/subtype"
                        });
                    })
                    .catch((err) => {
                        console.log(err);
                        swal("Erreur lors du traitement de la requete", {
                            icon: "error",
                        })
                    });
            }
        });
    };

    const handleDelete = (plateType) => {
        toggleConfirmDelete(plateType);
    };
    const handleChange = (event) => {
        
    }

    const edit = (plateType) => {
        setId(plateType.id);
        setName(plateType.name)
        if(plateType.type){
            setType(plateType.type)
        }
        setDescription(plateType.description);
        setModal(true);
    }

    const handleCreate = () => {
        toggleConfirmAdd();

    }

    const resetForm = () => {
        setId(0);
        setName("");
        setDescription("");
    };
    const toggle = () => {
        resetForm();
        setModal(!modal);
    };

    const closeBtn = (
        <FontAwesomeIcon
            className="close"
            onClick={toggle}
            style={{ cursor: "pointer", fontSize: "1.5rem" }}
            icon={faTimes}
        />
    );
    useEffect(() => {
        foodService
            .getFoodSubType()
            .then((response) => {
                setFoodSubType(response.data);
                console.log(response.data);
            })
            .catch((error) => {
                console.log(error);
            });
        foodService
            .getFoodType()
            .then((response) => {
                setFoodType(response.data);
                console.log(response.data);
            })
            .catch((error) => {
                console.log(error);
            });
    }, []);

    return (

        <Base>
            <div className="app-inner-layout">

                <div className="app-inner-layout__header-boxed p-0">
                    <div className="app-inner-layout__header text-dark bg-white mb-4 d-flex justify-content-between align-content-center">
                        <h1 className='fs-3 pt-2'>SubTypes de plats</h1>
                        <Button
                            className="btn-icon btn-icon-only pt-0 ps-2 wazi-btn-add float-end wazi-btn"
                            onClick={toggle}
                        >
                            <FontAwesomeIcon icon={faPlus} />
                        </Button>

                    </div>
                </div>

                <Table variant="dark" striped bordered hover>
                    <thead>
                        <tr>
                            <th>Nom</th>
                            <th>Etat</th>
                            <th>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            foodSubType.map((item, key) => {
                                return (
                                    <tr>
                                        <td>{item.name}</td>
                                        <td>{item.is_active ? <b className='text-success'>Actif</b> : <b className='wazieats-2-color'>Inactif</b>}</td>
                                        <td>
                                            <Row>
                                                <Col>
                                                    <Button className="btn-icon btn-icon-only pt-0 wazi-btn-add  wazi-btn" onClick={(e) => edit(item)}>
                                                        <FontAwesomeIcon icon={faPen} size="2xs" />
                                                    </Button>
                                                    <Button className=" btn-icon-only pt-0 wazi-btn-add " style={{ backgroundColor: "red", border: '1px solid red' }} onClick={(e) => handleDelete(item)} >
                                                        <FontAwesomeIcon icon={faTrash} size="2xs" />
                                                    </Button>
                                                </Col>

                                            </Row>
                                        </td>
                                    </tr>
                                )
                            })
                        }
                    </tbody>
                </Table>

                {/* </LoadingOverlay> */}

            </div>
            <Row>
                <Col md="12">
                    <Modal
                        className="wazi-modal"
                        isOpen={modal}
                        toggle={toggle}
                        size="lg"
                        backdrop="static"
                    >
                        <ModalHeader
                            style={{ fontSize: "1.5rem" }}
                            toggle={toggle}
                            close={closeBtn}
                        >
                            <FontAwesomeIcon icon={faUtensils} />
                            &nbsp;&nbsp;
                            {id === 0 ? "Ajouter un Subtype" : "Mise à jour d'un Subtype"}
                        </ModalHeader>
                        <ModalBody>
                            {loader ? (
                                <div className="text-center py-5">
                                    <div className="text-center py-5">
                                        <div className="text-center py-5">
                                            <Spinner
                                                thickness="4px"
                                                emptyColor="gray.200"
                                                color="green.500"
                                                size="xl"
                                            />
                                        </div>
                                    </div>
                                </div>
                            ) : (
                                <>
                                    <FormGroup row>
                                        <Label for="name" sm={3}>
                                            Nom du Subtype *
                                        </Label>
                                        <Col sm={9}>
                                            <Input
                                                value={name}
                                                onChange={(e) => setName(e.target.value)}
                                                type="text"
                                                name="name"
                                                id="name"
                                                placeholder="Entrer le nom du subtype"
                                            />
                                        </Col>
                                    </FormGroup>
                                    <FormGroup row>
                                        <Label for="description" sm={3}>
                                            Description sur le Subtype
                                        </Label>
                                        <Col sm={9}>
                                            <Input
                                                value={description}
                                                onChange={(e) => setDescription(e.target.value)}
                                                // disabled={dDesc}
                                                type="textarea"
                                                name="description"
                                                id="description"
                                                placeholder="Entrer la description du subtype"
                                            />
                                        </Col>
                                    </FormGroup>
                                    <FormGroup row>
                                        <Label for="type" sm={3}>
                                        Type
                                        </Label>
                                        <Col sm={9}>
                                            <Input
                                                value={type}
                                                onChange={e => setType(e.target.value)}
                                                type="select"
                                                // invalid={vType}
                                                // disabled={dType}
                                                name="type"
                                                id="type"
                                                >
                                                {(type === "" || type === null || type === undefined)
                                                    ? <option value="">Sélectionner un type</option>
                                                    : [foodType.find(obj => obj.id == type )].map(value => <option value={value.id}>{value.name}</option>)[0]}
                                                {foodType.map((value) => <option value={value.id}>{value.name}</option>)}
                                            </Input>
                                        </Col>
                                    </FormGroup>
                                    <FormGroup check row className="mt-5">
                                        <Col sm={{ size: 2, offset: 10 }}>
                                            <Button type="submit" block className="wazi-btn" onClick={() => handleCreate()}>
                                                {id === 0 ? "Ajouter" : "Mettre à jour"}
                                            </Button>
                                        </Col>
                                    </FormGroup>
                                </>

                            )}
                        </ModalBody>
                    </Modal>
                </Col>
            </Row>
        </Base>

    )

}