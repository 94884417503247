import http from './http-commons';
import authHeader from './authHeader';
import { API_URL } from './config';
import axios from 'axios';

class OrderService {

  getOrder(page=1, limit=10) {
    const params = {
      page: page,
      limit: limit,
    };
    const headers = authHeader();

    const config = {
      params,
      headers,
    };
    return http.get(API_URL + 'orders/cancelled', config);
  }
  getPendingOrder() {
    return http.get(API_URL + 'orders/today/', { headers:
          authHeader()
          // {"Authorization": 'Token fd548707241b64bd2a42b7f6b415c2e469a830d9', "Content-type": "application/json", }
    });
  }
  getApprovedOrder() {
    return http.get(API_URL + 'orders/approved', { headers: authHeader()
          // {"Authorization": 'Token fd548707241b64bd2a42b7f6b415c2e469a830d9', "Content-type": "application/json", }
    });
  }
  getOutForDeliveryOrder() {
    return http.get(API_URL + 'orders/delivery', { headers: authHeader()
          // {"Authorization": 'Token fd548707241b64bd2a42b7f6b415c2e469a830d9', "Content-type": "application/json", }
    });
  }
  DeliveryOrder = data => {
    return http.post(API_URL + 'orders/validate/delivery/',data, { headers: authHeader()
          // {"Authorization": 'Token fd548707241b64bd2a42b7f6b415c2e469a830d9', "Content-type": "application/json", }
    });
  }
   getCompletedOrders(page=1, limit=10) {
    const params = {
      page: page,
      limit: limit,
    };
    const headers = authHeader();

    const config = {
      params,
      headers,
    };
    return http.get(API_URL + 'orders/completed', config);
  }

}

export default new OrderService();
